<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <div class="d-flex justify-content-between">
          <select
            id="categorias"
            class="form-select"
            @change="categoryFilterChanged()"
          >
            <option
              v-for="accion in getCategorias()"
              :key="accion.category_id"
            >
              {{ accion.category_name }}
            </option>
          </select>
        </div>
        <CTableWrapper
          :items="datos_show"
        >
          <template #header>
            Usuarios
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/TablaUser.vue";
import {getUsersData, getCategoriasData} from '../../utils/utils';
import $ from "jquery";
import "core-js/stable";
import "regenerator-runtime/runtime";

export default {
  name: "UserTable",
  components: { CTableWrapper },
  data() {
    return {
      datos: [],
      categoria: [],
      temp: 0,
      tempC: 0,
      datos_show: [],
    };
  },
  created() {
    //Aquí llamamos al metodo para que se llamen a las funciones y recoger los datos
    this.getCategorias();
  },
  methods: {
    /**
     * Función para calcular la edad mediente una fecha
     * 
     * @returns {number} La edad del usuario
     */
    calcularEdad(fecha) {
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      return edad;
    },
    isDate(string) {
      try {
        string.includes("-");
        return true;
      } catch (error) {
        return false;
      }
    },
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla.
     */
    recibirDatos() {
      if (this.temp > 0) {
        return this.datos;
      }

      this.getCategorias();
      let data = getUsersData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.datos = result;
        this.datos = [];
        for (let index = 0; index < result.length; index++) {
          let element = {};
          element.username = result[index].username;
          let edad = this.calcularEdad(result[index].birthdate);
          element.edad = edad;
          let cat;
          for (let ind = 0; ind < this.categoria.length; ind++) {
            if (this.categoria[ind].category_id == result[index].category) {
              cat = this.categoria[ind].category_name;
              break;
            }
          }
          element.categoria = cat;
          element.acciones = result[index].user_id;
          this.datos.push(element);
        }

        this.datos_show = this.datos;
      });
      this.temp++;
    },
    /**
     * Función asociada al select que se llama cada vez que se selecciona
     * una categoría.
     */
    categoryFilterChanged() {
      var selectedCategory = $("#categorias").children("option:selected").val();
      this.selectCategorias(selectedCategory);
    },
    /**
     * Función que filtra la tabla para que aparezcan solo
     * los consejos con la categoría indicada.
     * 
     * @param {string} categoria - El nombre de la categoría por la que filtrar.
     */
    selectCategorias(categoria) {
      if (categoria == "Todas") {
        this.datos_show = this.datos;

        return;
      }

      this.datos_show = [];
      for (let i = 0; i < this.datos.length; i++) {
        const element = this.datos[i];
        if (element.categoria == categoria) {
          this.datos_show.push(element);
        }
      }
    },
    /**
     * Función para llamar a las funciones para conseguir los datos
     * 
     * @returns {object[]} Las categorias obtenidas
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categoria;
      }
      let cat = getCategoriasData();
      cat.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categoria = [
          {
            id: "Todas",
            category_name: "Todas",
          },
        ];
        this.categoria.push(...result);
        this.tempC++;
        this.recibirDatos();
      });
      return this.categoria;
    },
  },
};
</script>
